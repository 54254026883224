<template>
  <v-dialog
    v-model="services.showCreateChildren"
    width="500"
  >
    <v-card>
      <v-card-title v-if="!isEditing">
        Create Children Node
      </v-card-title>
      <v-card-title v-else>
        Edit Children Node
      </v-card-title>
      <v-card-text>
        <v-form
          ref="childrenForm"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="childrenForm.node_name"
            label="Children Node Name"
            required
            :rules="name_rules"
            :counter="255"
          />
          <v-text-field
            v-model="childrenForm.node_description"
            label="Children Node Description"
          />
          <div v-if="!ticketing.isProfiling">
            <v-text-field
              v-if="routeType == 'URL'"
              v-model="childrenForm.external_link"
              label="External Link"
            />
            <div v-else>
              <div v-if="!isTranslating">
                <div
                  v-if="
                    childrenForm.path_s3_bucket != '' &&
                      childrenForm.path_s3_bucket != undefined
                  "
                  class="mb-2 ml-8"
                >
                  <v-chip
                    small
                    label
                    color="color_green"
                    style="color:white"
                  >
                    <i>Existing uploaded file {{ splitBucketS3Path }}</i>
                  </v-chip>
                </div>
                <v-file-input
                  v-model="document"
                  accept=".pdf"
                  label="Insert Document"
                  placeholder="Upload your Document"
                  prepend-inner-icon="mdi-file-pdf-box"
                  outlined
                  truncate-length="50"
                  @change="checkFileExt"
                />
                <v-alert
                  :value="alert"
                  color="red accent-4"
                  dark
                  transition="scale-transition"
                >
                  extension file not allowed, please insert <b>.pdf</b>
                </v-alert>
              </div>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!isEditing"
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          :disabled="checkForm"
          @click="createChildren"
        >
          Create Children Node
        </v-btn>
        <v-btn
          v-else
          light
          color="color_green"
          style="color:white"
          class="ml-4"
          rounded
          small
          :disabled="checkForm"
          @click="editChildren"
        >
          Edit Children Node
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, manageLenghtError } from '@/formRules.js';
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { editItemNested } from '@/utils.js';
import { S3_BUCKETS } from '@/constants.js';

export default {
  props: {
    father: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isTranslating: {
      type: Boolean,
      default: false
    },
    childrenForm: {
      type: Object,
      default: null
    },
    routeType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      name_rules: [...required, ...manageLenghtError(255)],
      valid: true,
      document: null,
      path_s3_bucket: '',
      alert: false
    };
  },
  computed: {
    ...mapState(['services', 'ticketing']),
    splitBucketS3Path() {
      const res = this.childrenForm.path_s3_bucket.split('/');

      return res[1];
    },
    checkForm() {
      return this.childrenForm.node_name === '' || !this.valid;
    }
  },
  methods: {
    ...mapActions(['setUpload']),
    async createChildren() {
      await this.confirmFile();
      const id = uuidv4();
      const service_id = JSON.parse(JSON.stringify(this.services.service_id));

      const objToPush = {
        service_id: service_id,
        node_name: this.childrenForm.node_name,
        node_description: this.childrenForm.node_description,
        node_id: id,
        father_id: this.father.node_id,
        children: [],
        path_s3_bucket: this.path_s3_bucket,
        external_link: this.childrenForm.external_link,
        sort_id: ''
      };

      if (!this.ticketing.isProfiling) {
        objToPush.sort_id = ++this.services.storeChildrenSort_id;
      } else {
        let father_id = JSON.parse(JSON.stringify(this.father.sort_id));
        objToPush.sort_id = ++father_id;
      }

      this.father.children.push(objToPush);
      this.services.showCreateChildren = false;
    },
    async editChildren() {
      await this.confirmFile();
      editItemNested(
        this.services.hierarchyItems,
        this.childrenForm.node_id,
        'children',
        this.childrenForm
      );
      this.services.showCreateChildren = false;
    },
    checkFileExt() {
      if (this.document) {
        this.alert = this.document.type != 'application/pdf';
      } else {
        this.alert = false;
      }
    },
    async confirmFile() {
      if (!this.ticketing.isProfiling && this.routeType !== 'URL' && this.document && this.document.type === 'application/pdf') {
        if (this.document.type == 'application/pdf') {
          const service_id = JSON.parse(JSON.stringify(this.services.service_id));
          const params = {
            Bucket: S3_BUCKETS.application(),
            Key: `${service_id}/${this.document.name}`,
            Body: this.document
          };
          await this.$store.dispatch('setUpload', params);
          this.path_s3_bucket = `${service_id}/${this.document.name}`;
          this.childrenForm.path_s3_bucket = `${service_id}/${this.document.name}`;
          this.alert = false;
        } else {
          this.alert = true;
        }
      }
    }
  }
};
</script>
